import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import comunasData from "./KinesiologosPag";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Collapse,
  Rating,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import Skeleton from "@mui/material/Skeleton";
import { Helmet } from "react-helmet";
import { Star } from "@mui/icons-material";

import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import EvaluacionesSection from "./Evaluaciones";
import ProfessionalInfo from "./HeaderPerfil";

function Profesionales() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [kinesiologoInfo, setKinesiologoInfo] = useState(null);
  let history = useHistory();
  const [showButton, setShowButton] = useState(true);
  const [expandedService, setExpandedService] = useState(null);
  const [visibleServiceType, setVisibleServiceType] = useState(null);

  const { comunaUrl } = useParams();
  const getComunaInfo = (url) => {
    return Object.values(comunasData).find((comuna) => comuna.url === url);
  };
  const id_profesional = getComunaInfo(comunaUrl);
  const { id_profesional_fantasma, url } = id_profesional;
  const [perfilDatos, setPerfilDatos] = useState(null);
  const pageTitle = "Teradomus";
  const pageDescription =
    "Kinesiología: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const url = `/agenda/perfil?id_profesional_fantasma=${id_profesional_fantasma}`;
        const response = await callApi(url);
        setKinesiologoInfo(response);
        setPerfilDatos(response.perfil);
      } catch (error) {
        console.error(
          "Error al obtener la información del kinesiólogo:",
          error
        );
        MySwal.fire(
          "Error",
          "No se pudo cargar la información del kinesiólogo.",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (id_profesional_fantasma) fetchData();
  }, [id_profesional_fantasma]);

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      setShowButton(position < (2 / 3) * height);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const RatingDisplay = ({ evaluaciones }) => {
    if (!evaluaciones) return null;

    const rating = parseFloat(evaluaciones.promedio);
    const totalReviews = evaluaciones.cantidad;

    const handleClick = () => {
      const evaluacionesSection = document.getElementById(
        "evaluaciones-section"
      );
      if (evaluacionesSection) {
        evaluacionesSection.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    return (
      <Box
        onClick={handleClick}
        sx={{
          display: "inline-flex",
          alignItems: "center",
          gap: 0.5,
          ml: 1,
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Rating
            value={rating}
            readOnly
            precision={0.1}
            size="small"
            emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
          />
        </Box>
        <Typography
          component="span"
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: "16px",
            color: "text.secondary",
          }}
        >
          {evaluaciones.promedio}
        </Typography>
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            color: "text.secondary",
            fontSize: "16px",
          }}
        >
          <span>(</span>
          <Typography
            component="span"
            sx={{
              textDecoration: "underline",
              padding: "0 1px",
              fontFamily: "Quicksand-Regular",
            }}
          >
            {totalReviews} evaluaciones
          </Typography>
          <span>)</span>
        </Box>
      </Box>
    );
  };

  const renderKinesiologoInfo = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton
            animation="wave"
            variant="text"
            height={35}
            sx={{ ml: 2, width: isMobile ? "70%" : "40%" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            width="30%"
            height={25}
            sx={{ ml: 2 }}
          />
        </>
      );
    }

    return <ProfessionalInfo kinesiologoInfo={kinesiologoInfo} />;
  };

  const renderKinesiologoDescription = () => {
    if (isLoading) {
      return (
        <Skeleton animation="wave" variant="text" width="100%" height={200} />
      );
    }

    if (kinesiologoInfo && kinesiologoInfo.perfil) {
      const description = kinesiologoInfo.perfil.profesional_descripcion;
      const paragraphs = description
        .split("\n")
        .filter((paragraph) => paragraph.trim() !== "");

      return (
        <>
          {paragraphs.map((paragraph, index) => (
            <Typography
              key={index}
              sx={{
                mb: 2,
                fontFamily: "Quicksand-Regular",
                whiteSpace: "pre-wrap",
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </>
      );
    }

    return (
      <Typography sx={{ fontFamily: "Quicksand-Regular" }}>
        No se encontró información del kinesiólogo.
      </Typography>
    );
  };
  const handleExpandClick = (serviceId) => {
    setExpandedService(expandedService === serviceId ? null : serviceId);
  };

  const toggleServiceVisibility = (serviceType) => {
    setVisibleServiceType(
      visibleServiceType === serviceType ? null : serviceType
    );
  };

  const renderServiceCards = () => {
    if (isLoading) {
      return <Skeleton variant="rectangular" width="100%" height={118} />;
    }

    if (!kinesiologoInfo) {
      return (
        <Typography sx={{ fontFamily: "Quicksand-Regular", color: "#9f9f9f" }}>
          No se encontró información del kinesiólogo.
        </Typography>
      );
    }

    const { servicio_consulta, servicio_domicilio, servicio_remoto } =
      kinesiologoInfo;

    if (!servicio_consulta && !servicio_domicilio && !servicio_remoto) {
      return (
        <Typography sx={{ fontFamily: "Quicksand-Regular", color: "#9f9f9f" }}>
          No hay servicios disponibles en este momento.
        </Typography>
      );
    }

    const renderServiceSection = (title, serviceType, services) => {
      if (!services || services.length === 0) return null;

      return (
        <Box
          sx={{ mb: 4, bgcolor: "white", p: 2, borderRadius: 2, boxShadow: 1 }}
        >
          <Typography
            sx={{ fontFamily: "Quicksand-Bold", fontSize: "20px", mb: 2 }}
          >
            {title}
          </Typography>
          {serviceType === "consulta" && kinesiologoInfo.cobertura_consulta && (
            <Typography sx={{ fontFamily: "Quicksand-Regular", mb: 1 }}>
              Atención presencial en: {kinesiologoInfo.cobertura_consulta}
              {kinesiologoInfo.cobertura_consulta_referencia &&
                ` - ${kinesiologoInfo.cobertura_consulta_referencia}`}
            </Typography>
          )}
          {serviceType === "domicilio" &&
            kinesiologoInfo.cobertura_domicilio && (
              <Typography sx={{ fontFamily: "Quicksand-Regular", mb: 1 }}>
                Atención a domicilio en:{" "}
                {kinesiologoInfo.cobertura_domicilio.join(", ")}
              </Typography>
            )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => toggleServiceVisibility(serviceType)}
              sx={{
                color: "#8753b1",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "rgba(135, 83, 177, 0.04)",
                },
              }}
            >
              {visibleServiceType === serviceType
                ? "Ocultar servicios"
                : "Mostrar servicios"}
            </Button>
          </Box>
          <Collapse in={visibleServiceType === serviceType}>
            {services.map((servicio) => (
              <Card
                key={servicio.id_servicio_fantasma}
                sx={{ mb: 2, borderRadius: "16px", boxShadow: 3 }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontFamily: "Quicksand-Bold", fontSize: "18px" }}
                  >
                    {servicio.servicio_nombre}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Quicksand-Regular", color: "#9f9f9f" }}
                  >
                    {servicio.servicio_duracion}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Quicksand-Regular", color: "#9f9f9f" }}
                  >
                    {transformModalidad(servicio.servicio_modalidad)}
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Quicksand-Bold", color: "#9f9f9f" }}
                  >
                    {servicio.servicio_precio}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <img
                      src="/chevron-down-solid.svg"
                      alt="flecha"
                      onClick={() =>
                        handleExpandClick(servicio.id_servicio_fantasma)
                      }
                      style={{
                        width: "22px",
                        height: "22px",
                        cursor: "pointer",
                        filter:
                          "invert(35%) sepia(100%) saturate(2000%) hue-rotate(190deg) brightness(90%) contrast(85%)",
                        transform:
                          expandedService === servicio.id_servicio_fantasma
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => handleAgendarClick(servicio)}
                      sx={{
                        fontFamily: "Quicksand-Bold",
                        backgroundColor: "#8753b1",
                        "&:hover": { backgroundColor: "#651d87" },
                        borderRadius: 3,
                      }}
                    >
                      Agendar
                    </Button>
                  </Box>
                </CardContent>
                <Collapse
                  in={expandedService === servicio.id_servicio_fantasma}
                >
                  <CardContent>
                    <Typography
                      sx={{ fontFamily: "Quicksand-Regular", color: "#9f9f9f" }}
                    >
                      {servicio.servicio_descripcion}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </Collapse>
        </Box>
      );
    };

    return (
      <>
        {servicio_consulta &&
          renderServiceSection(
            "Consulta presencial",
            "consulta",
            kinesiologoInfo.servicios.filter(
              (s) => s.servicio_modalidad === "consultorio"
            )
          )}
        {servicio_domicilio &&
          renderServiceSection(
            "Consulta a Domicilio",
            "domicilio",
            kinesiologoInfo.servicios.filter(
              (s) => s.servicio_modalidad === "domicilio"
            )
          )}
        {servicio_remoto &&
          renderServiceSection(
            "Consulta online",
            "remoto",
            kinesiologoInfo.servicios.filter(
              (s) => s.servicio_modalidad === "online"
            )
          )}
      </>
    );
  };

  const handleAgendarClick = (servicio) => {
    if (kinesiologoInfo && kinesiologoInfo.perfil) {
      const {
        profesional_nombre_plataforma,
        profesional_profesion,
        profesional_telefono,
      } = kinesiologoInfo.perfil;

      history.push({
        pathname: "/pro/perfil/calendario",
        state: {
          titulo: profesional_nombre_plataforma,
          profesion: profesional_profesion,
          celukine: profesional_telefono,
          id_kinesiologo: id_profesional_fantasma,
          id_servicios: servicio.id_servicio_fantasma,
          servicio_nombre: servicio.servicio_nombre,
          servicio_duracion: servicio.servicio_duracion,
          servicio_precios: servicio.servicio_precio,
          servicio_modalidad: servicio.servicio_modalidad,
          disponibilidad: servicio.disponibilidad,
          perfil_pago: kinesiologoInfo.perfil_pago,
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <img
        src={logoImage}
        alt="Teradomus Logo"
        style={{
          width: isMobile ? "150px" : "200px",
          height: "auto",
          paddingTop: "15px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          padding: 15,
        }}
      />
      <Box
        sx={{
          width: isMobile ? "94%" : "50%",
          mx: "auto",
          bgcolor: "#fbfafe",
          boxShadow: 1,
          marginTop: 2,
          marginBottom: 8,
          borderRadius: 2,
          p: isMobile ? 2 : 4,
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
        }}
      >
        {renderKinesiologoInfo()}
        <img
          src={id_profesional.image}
          alt={id_profesional.comun}
          style={{
            width: isMobile ? "95%" : "95%",
            height: "auto",
            display: "block",
            margin: "auto",
            backgroundSize: "cover",
            borderRadius: "8px",
            marginTop: 3,
          }}
        />
        <Box sx={{ p: isMobile ? 1 : 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "flex-start" : "flex-start",
              mb: 1,
            }}
          >
            <img
              src="/escudo.svg"
              alt="escudo"
              style={{
                width: "27px",
                paddingRight: "10px",
                filter:
                  "invert(35%) sepia(100%) saturate(2000%) hue-rotate(190deg) brightness(90%) contrast(85%)",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { md: "16px", xs: "14px" },
                textAlign: "left",
              }}
            >
              {isMobile
                ? "Certificado por Superintendencia de Salud"
                : "Profesional certificado por Superintendencia de Salud"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: isMobile ? "flex-start" : "left",
              mb: 1,
            }}
          >
            <img
              src="/dinero.svg"
              alt="dinero"
              style={{
                width: isMobile ? "27px" : "27px",
                paddingRight: "10px",
                filter:
                  "invert(35%) sepia(100%) saturate(2000%) hue-rotate(190deg) brightness(90%) contrast(85%)",
              }}
            />
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: { md: "16px", xs: "14px" },
              }}
            >
              {isMobile
                ? "Reembolsable por ISAPRE y Seguros"
                : "Las atenciones de este profesional son Reembolsable por ISAPRE y Seguros"}
            </Typography>
          </Box>
          <Typography
            sx={{
              mb: 2,
              mt: 2,
              fontFamily: "Quicksand-Bold",
              fontSize: { md: "26px", xs: "18px" },
            }}
          >
            Sobre mí
          </Typography>
          {renderKinesiologoDescription()}
          <Typography
            id="agendar"
            sx={{
              mt: 3,
              mb: 2,
              fontFamily: "Quicksand-Bold",
              fontSize: { md: "26px", xs: "18px" },
            }}
          >
            Agenda tu hora{" "}
          </Typography>
          {renderServiceCards()}
          {kinesiologoInfo && kinesiologoInfo.evaluaciones && (
            <EvaluacionesSection evaluaciones={kinesiologoInfo.evaluaciones} />
          )}
        </Box>
      </Box>
    </>
  );
}

export default Profesionales;
