import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Link } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import logo from "../../../assets/logo.png";
import mercadoPago from "../../../assets/newiconos/mer.png";
import gobChile from "../../../assets/newiconos/gob.png";
import startupChile from "../../../assets/newiconos/zap.png";
import WhatsAppIcon from "../../../assets/newiconos/was.png";

const estiloComun = {
  fontFamily: "Quicksand-Regular",
  fontSize: "14px",
};

const estiloTitulo = {
  ...estiloComun,
  fontFamily: "Quicksand-Bold",
  fontSize: "16px",
  marginBottom: "5px",
  color: "#216ba8",
  marginTop: "20px",
};

export default function FooterProfesion() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const FooterContent = () => (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography
          sx={{
            ...estiloComun,
            backgroundColor: "#216ba8",
            color: "white",
            padding: "7.5px 16px",
            borderRadius: "30px",
            fontSize: "14px",
            fontFamily: "Quicksand-Bold",
            mt: -5,
          }}
        >
          2024
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#216ba8",
            fontFamily: "Quicksand-Bold",
            textTransform: "none",
            borderRadius: "20px",
            padding: "6px 16px",
            fontSize: "14px",
            mt: -5,
          }}
        >
          Empieza hoy gratis
        </Button>
      </Box>

      {isMobile ? (
        <>
          <Box sx={{ display: "flex", mb: 4 }}>
            <img src={logo} alt="Teradomus" style={{ height: 40 }} />
          </Box>
          <Typography sx={{ ...estiloComun, fontSize: "14px" }}>
            Con el apoyo de
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <img src={mercadoPago} alt="Mercado Pago" style={{ height: 60 }} />
            <img
              src={gobChile}
              alt="Gobierno de Chile"
              style={{ height: 60 }}
            />
            <img
              src={startupChile}
              alt="Start-Up Chile"
              style={{ height: 50, marginTop: 5 }}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <img src={logo} alt="Teradomus" style={{ height: 30 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <Typography sx={{ ...estiloComun, mr: 2, fontSize: "12px" }}>
              Con el apoyo de
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                src={mercadoPago}
                alt="Mercado Pago"
                style={{ height: 60 }}
              />
              <img
                src={gobChile}
                alt="Gobierno de Chile"
                style={{ height: 60 }}
              />
              <img
                src={startupChile}
                alt="Start-Up Chile"
                style={{ height: 60 }}
              />
            </Box>
          </Box>
        </Box>
      )}

      <Grid container>
        <Grid item xs={isMobile ? 6 : 3}>
          <Typography sx={estiloTitulo}>Menú</Typography>
          <Typography sx={estiloComun}>
            <Link
              href="/kinesiologia-a-domicilio"
              color="inherit"
              style={{ display: "block" }}
            >
              Servicios
            </Link>
            <Link href="/blog" color="inherit" style={{ display: "block" }}>
              Blog
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={isMobile ? 6 : 3}>
          <Typography sx={estiloTitulo}>Legal</Typography>
          <Typography sx={estiloComun}>
            <Link
              href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/CONDICIONES_CLIENTES.pdf"
              color="inherit"
              style={{ display: "block" }}
            >
              Términos y condiciones
            </Link>
            <Link
              href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/CONDICIONES_CLIENTES.pdf"
              color="inherit"
              style={{ display: "block" }}
            >
              Políticas de privacidad
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={isMobile ? 6 : 3}>
          <Typography sx={estiloTitulo}>Contacto</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link
              href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola%20amigos%20de%20Teradomus!%20Estoy%20interesado%20en%20tomar%20un%20tratamiento%20de%20kinesiolog%C3%ADa%20con%20ustedes.%20%C2%BFPodr%C3%ADan%20ayudarme%20a%20agendar%20una%20cita%3F&type=phone_number&app_absent=0"
              color="inherit"
              style={estiloComun}
            >
              Whatsapp
            </Link>
          </Box>
          <Typography sx={estiloComun}>
            <Link href="mailto:contacto@teradomus.com" color="inherit">
              Mail
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={isMobile ? 6 : 3}>
          <Typography sx={estiloTitulo}>Acerca de</Typography>
          <Typography sx={estiloComun}>
            <Link href="https://teradomus.com/inscripcion" color="inherit">
              Atiende con Teradomus
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Box
      sx={{
        backgroundColor: "#f4e6ff",
        pt: 2,
        pb: isMobile ? 3 : 5,
        position: "relative",
        borderRadius: "20px",
        mt: isMobile ? 15 : 15,
        mb: isMobile ? 4 : 8,
      }}
    >
      <Container maxWidth="lg">
        <FooterContent />
      </Container>
    </Box>
  );
}
